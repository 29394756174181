export const config = {
  getPiralBackendUrl: () => {
    const sandbox = 'dev';
    //@ts-ignore gets filled during deployment, not relevant
    if (sandbox === 'dev') {
      return 'https://api.olive-team.com/piral/';
    } else if (sandbox === "dev" && !sandbox.startsWith('$')) {
      return `https://api.${sandbox}.olive-team.com/piral/`;
    } else {
      return 'https://cloud.omilab.org/api/piral-backend/';
    }
  },
  getDataLayerUrl: () => {
    const sandbox = 'dev';
    //@ts-ignore gets filled during deployment, not relevant
    if (sandbox === 'dev') {
      return 'https://api.olive-team.com/data-layer/';
    } else if (sandbox === "dev" && !sandbox.startsWith('$')) {
      return `https://api.${sandbox}.olive-team.com/data-layer/`;
    } else {
      return 'https://cloud.omilab.org/api/data-layer/';
    }
  },

};

